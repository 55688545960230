import { do_request } from '../http'
import  setting  from '../setting/index.js';

//获取个人信息数据

export const getLoginInfo = () => {
    let uri = setting.HOST + 'cartoon/index'
    return do_request(uri, {

    })
}

//修改个人资料
export const userInfoEdit = (data) => {
    let uri = setting.HOST + 'user/edit'
    return do_request(uri, {
        method:"post",
        data: data
    })
}

// 密码修改
export const changePassword = (data) => {
    let url = setting.HOST + 'password/modifypwd'
    return do_request(url, {
        method:"post",
        data: data
    })
}

//上传图标 project/uploadicon
export const projectUploadIcon = (data) => {
    let uri = setting.HOST + 'project/uploadicon'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//企划书上传 /project/upload
export const projectUpload = (data) => {
    let uri = setting.HOST + 'project/upload'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
// 获取项目列表 project/ getplist
export const getProjectList = (data) => {
    let uri = setting.HOST + 'project/getplist'
    return do_request(uri, {
        method: 'get',
        params: data
       
    })
}
//获取分类 project/index
export const getTypeData = () => {
    let uri = setting.HOST + 'project/index'
    return do_request(uri, {
        
    })
}

//新项目立项 
export const creatProject = (data) => {
    let uri = setting.HOST + 'project/create'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
// 好友编辑列表project/getflist
export const getEditList = () => {
    let uri = setting.HOST + 'project/getflist'
    return do_request(uri, {
        method: 'get',
    })
}

//邀请编辑project/invitation
export const invitationEdit = data => {
    let uri = setting.HOST + 'project/invitation' 
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//创建文件夹
export const creatFload = data => {
    let uri = setting.HOST + 'project/mkdir'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取文件夹

export const getFload = data => {
    let uri = setting.HOST + 'project/getdir'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//文件夹内上传文件
export const uploadFileorpic = (data) => {
    let uri = setting.HOST + 'project/uploadFileorpic'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}

//获取项目信息 
export const getProjectInfo = data => {
    let uri = setting.HOST + 'project/getpinfo'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

// 获取文件夹内容列表 /project/ getfilelist
export const getProjectFileList = data => {
    let uri = setting.HOST + 'project/getfilelist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

// 下载文件 project/ downloadfile
export const downLoadFile = data => {
    let uri = setting.HOST + 'project/downloadfile'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取编辑参与的项目 personal/getmixlist
export const getPartProList = (data) => {
    let uri = setting.HOST + 'personal/getmixlist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
// 获取配对用户 personal/pushpair
export const getPersonalPushPair = () => {
    let uri = setting.HOST + 'personal/pushpair'
    return do_request(uri, {
        method: 'get',
    })
} 
//删除文件 /project/delfile
export const deleteFile = data => {
    let uri = setting.HOST + 'project/delfile'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取文件注释  /project/getfilenote
export const getFileNote = data => {
    let uri = setting.HOST + 'project/getfilenote'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//写入文件注释  /project/addfilenote
export const addFileNote = data => {
    let uri = setting.HOST + 'project/addfilenote'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//主页获取项目列表 personal/getPlist
export const getPersonalProjectList = (page) => {
    let uri = setting.HOST + `personal/getPlist?p=${page}`
    return do_request(uri, {
        method: 'get'
    })
}

//创建案件
export const creatCase = (data) => {
    let uri = setting.HOST + 'chapter/create'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//是否添加好友  friend/isadd
export const isAddFriend = (data) => {
    let uri = setting.HOST + 'friend/isadd'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//案件图标上传
export const uploadCapterPic = (data) => {
    let uri = setting.HOST + 'chapter/uploadFile'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
// 获取案件列表 
export const getCaseList = (data) => {
    let uri = setting.HOST + 'chapter/list'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//招聘  /project/ pubrecruit
export const recruitInfo = (data) => {
    let uri = setting.HOST + 'project/pubrecruit'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}

//招聘上传图标 /project/ uploadrecFile
export const uploadrecFile = (data) => {
    let uri = setting.HOST + 'project/uploadrecFile'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}

// 推荐招聘信息 personal/ getrecruit
export const getRecruitList = (data,page) => {
    let uri = setting.HOST + `personal/getrecruit?kind=${data}&p=${page}`
    return do_request(uri, {
        method: 'get',
    })
}
export const getQuerestList = (data) => {
    let uri = setting.HOST + 'find/getpairlist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取应聘列表 project/getapplylist
export const getApplayList = (data) => {
    let uri = setting.HOST + 'project/getapplylist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//应聘职位  personal/apply
export const personalApply = (data) => {
    let uri = setting.HOST + 'personal/apply'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

// 处理应聘信息 project/ handleapply
export const handleApply = (data) => {
    let uri = setting.HOST + 'project/handleapply'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取项目简介  chapter/viewchapter
export const getChapter = (data) => {
    let uri = setting.HOST + 'chapter/viewchapter'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取案件原稿页面/chapter/draft
export const getChapterDraft = (data) => {
    let uri = setting.HOST + 'chapter/draft'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//初稿上传 /chapter/uploaddraft
export const chapterUploaddraft = (data) => {
    let uri = setting.HOST + 'chapter/uploaddraft'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//初稿添加新页
export const creatPage = (data) => {
    let uri = setting.HOST + 'chapter/addpage'
    return do_request(uri, {
        method: 'get',
        params: data,
        loadingHide:false
    })
}

//写入编辑/chapter/addpagenote
export const addPageNote = (data)=> {
    let uri = setting.HOST + 'chapter/addpagenote'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//发送编辑邮件/chapter/ sendEmail
export const  sendEditEmail = (data) => {
    let uri = setting.HOST + 'chapter/sendEmail'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//编辑审核 /chapter/checkdraft
export const editCheckdraft = (data) => {
    let uri = setting.HOST + 'chapter/checkdraft'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//工作流程  chapter/procedure
export const chapterProcedure = (data) => {
    let uri = setting.HOST + 'chapter/procedure'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//设置工作流程 /chapter/setprocedure
export const setChapterProcedure = (data) => {
    let uri = setting.HOST + 'chapter/setprocedure'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//删除流程
export const deletePageUser = (data) => {
    let uri = setting.HOST + 'chapter/delpageuser'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//添加流程 chapter/addpageuser
export const addPageUser = (data) => {
    let uri = setting.HOST + 'chapter/addpageuser'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//文件下载/chapter/downloadpage 
export const downLoadPage = (data) => {
    let uri = setting.HOST + 'chapter/downloadpage'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//文件上传/chapter/ uploadpage
export const upLoadPage = (data) => {
    let uri = setting.HOST + 'chapter/uploadpage'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//获取履历 /chapter/ gethistory
export const getHistory = (data) => {
    let uri = setting.HOST + 'chapter/gethistory'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//通过 chapter/ passpage
export const passPage = (data) => {
    let uri = setting.HOST + 'chapter/passpage'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//反工 chapter/backpage
export const backPage = (data) => {
    let uri = setting.HOST + 'chapter/backpage'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取页的列表
export const getPageList = (data) => {
    let uri = setting.HOST + 'chapter/pagelist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//好友列表  friend/getflist
export const getFriendList = () => {
    let uri = setting.HOST + 'friend/getflist'
    return do_request(uri, {
        method: 'get',
    })
}
//添加好友friend/addfriend
export const addFriend = (data) => {
    let uri = setting.HOST + 'friend/addfriend'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//获取好友消息列表 friend/getmsglist
export const getMessageList = (data) => {
    let uri = setting.HOST + 'friend/getmsglist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//查找好友 friend/searchfriend
export const getSearchList = (data) => {
    let uri = setting.HOST + 'friend/searchfriend'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//解除好友关系 friend/delfriend
export const deleteFriend = (data) => {
    let uri = setting.HOST + 'friend/delfriend'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//发送消息
export const sendMessage = (data) => {
    let uri = setting.HOST + 'friend/sendmsg'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

// 查找项目 find/project      
export const findProject = (data) => {
    let uri = setting.HOST + 'find/project'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//用户搜索 find/index
export const findUser = (data) => {
    let uri = setting.HOST + 'find/index'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
// 获取媒体 find/getmedia
export const getMedia = (data) => {
    let uri = setting.HOST + 'find/getmedia'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//申请配对 find/applypair
export const applyPair = (data) => {
    let uri = setting.HOST + 'find/applypair'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//个人主页 userintroduction
export const getUserInfoData = (data) => {
    let uri = setting.HOST + 'userintroduction'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//推荐项目 find/pushproject
export const pushProject = (data) => {
    let uri = setting.HOST + 'find/pushproject'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//推荐案件find/pushchapter'
export const pushChapter = (data) => {
    let uri = setting.HOST + 'personal/getchapterlist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//招聘列表

export const getRecruitDetialsList = (data) => {
    let uri = setting.HOST + 'project/getgetapplydetaillist'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//初稿删除

export const deleteChapterPage = (data) => {
    let uri = setting.HOST + 'chapter/delpage'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//删除消息/friend/upstatus 

export const deleteMessage = (data) => {
    let uri = setting.HOST + 'friend/upstatus'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//初稿审核不通过 /chapter/refusedraft
export const noPass = (data) => {
    let uri = setting.HOST + 'chapter/refusedraft'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}

//获取留言列表 /msgboard/getMsgbList
export const getMsgbList = (data) => {
    let uri = setting.HOST + 'msgboard/getMsgbList'
    return do_request(uri, {
        method: 'get',
        params: data
    })
}
//发送留言/msgboard/reply 
export const msgboardReply = (data) => {
    let uri = setting.HOST + 'msgboard/reply'
    return do_request(uri, {
        method: 'get',
        params: data
    }) 
}

//获取章节 chapter/getchptilte

export const getchpTitle = (data) => {
    let uri = setting.HOST + 'chapter/getchptilte'
    return do_request(uri, {
        method: 'get',
        params: data
    }) 
}

//获取项目人员  project/getprojectuser
export const getProjectUser = (data) => {
    let uri = setting.HOST + 'project/getprojectuser'
    return do_request(uri, {
        method: 'get',
        params: data
    }) 
}

//案件检索 find/chapter
export const findChapter = (data) => {
    let uri = setting.HOST + 'find/chapter'
    return do_request(uri, {
        method: 'get',
        params: data
    }) 
}

//获取好友列表
export const findGetFriends = () => {
    let uri = setting.HOST + 'find/getfriends'
    return do_request(uri, {
        method: 'get',
    }) 
}

//获取招聘信息 /project/getrecruitlist
export const projectGetrecruitList = () => {
    let uri = setting.HOST + 'project/getrecruitlist'
    return do_request(uri, {
        method: 'get',
    }) 
}

// 通过/chapter/checkpage
export const chapterCheckpage = (data) => {
    let uri = setting.HOST + 'chapter/checkpage'
    return do_request(uri, {
        method: 'get',
        params: data
    }) 
}

// 企划招聘/project/getnewPapplylist
export const getNewPapplylist = (data) => {
    let uri = setting.HOST + 'project/getnewPapplylist'
    return do_request(uri, {
        method: 'get',
        params:data
    }) 
}
//案件招聘 /project/getnewCapplylist
export const getNewCapplylist = (data) => {
    let uri = setting.HOST + 'project/getnewCapplylist'
    return do_request(uri, {
        method: 'get',
        params:data
    }) 
}

//自动分配 chapter/autobatch
export const chapterAutobatch = (data) => {
    let uri = setting.HOST + 'chapter/autobatch'
    return do_request(uri, {
        method: 'get',
        params:data
    }) 
}

//结束招聘 project/endrecruit
export const endRecruit = (data) => {
    let uri = setting.HOST + 'project/endrecruit'
    return do_request(uri, {
        method: 'post',
        params: data
    }) 
}
//获取职位 /chapter/getPositions

export const getPositions = (data) => {
    let uri = setting.HOST + 'chapter/getPositions'
    return do_request(uri, {
        method: 'get',
        params: data
    }) 
}