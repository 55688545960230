import { do_request } from '../http'
import  setting  from '../setting/index.js';
//注册 获取注册相关数据
export const getUserInfo = () => {
    let uri = setting.HOST + 'user/register'
    return do_request(uri, {

    })
}
//图片上传
export const userUpload = (data) => {
    let uri = setting.HOST + 'user/upload'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//账号注册
export const userSign = (data) => {
    let uri =  setting.HOST + 'user/register'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//用户登录
export const userLogin = (data) => {
    let uri =  setting.HOST + 'user/login'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}
//退出登录
export const userLogout = () => {
    let uri =  setting.HOST + 'admin/logout'
    return do_request(uri, {
        method: 'get',
    })
}
//获取token 
export const getToken = () => {
    let uri =  setting.HOST + 'getToken'
    return do_request(uri, {

    })
}
 
//发送验证码 password/checkusername

export const getCode = (data) => {
    let uri =  setting.HOST + 'password/checkusername'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}

//重置密码 password/updatepwd
export const resetPassWord = (data) => {
    let uri =  setting.HOST + 'password/updatepwd'
    return do_request(uri, {
        method: 'post',
        data: data
    })
}

