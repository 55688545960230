import axios from 'axios'
import router from '../router'

import {
    Loading
  } from 'element-ui';
let request_ = axios.create({
    timeout: 1000000
})

request_.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'



let loadingInstance = null; // 记录页面中存在的loading
let loadingCount = 0; // 记录当前正在请求的数量
 
function showLoading(data) {
  if (loadingCount === 0) {
    loadingInstance = Loading.service({
      lock: true,
      text: data || 'ロード中……',
      background:'rgba(0, 0, 0, 0.6)'
      
    });
  }
  loadingCount++
};
 
function hideLoading() {
  loadingCount--
  if (loadingInstance && loadingCount === 0) {
    loadingInstance.close()
    loadingInstance = null
  }
}



//请求拦截
request_.interceptors.request.use((config) => {
    //发送请求之前要做的事情放在这里
    if (!config.loadingHide) {//有的请求隐藏loading
        showLoading(config.loadingText)
    }
    return config
},(err)=>{
    //请求错误之后要做的事情放这里
    return Promise.reject(err)
});

//添加响应拦截器
request_.interceptors.response.use((response) => {
    if (!response.config.loadingHide) {//有的请求需要隐藏loading
        hideLoading()
    }
    //正常返回数据
   if(response.data.status === 401){
        setTimeout(() => {
            router.push({
                path: "/login"
            })
        }, 500);
   }
    return response
    
},(err) => {
    hideLoading()
    //接口抛出错误
    if(err.response.status === 500) {
        alert("サーバーエラー")
    }
    return Promise.reject(err)
})

export async function do_request(url, opts = {}) {
    return new Promise((resolve, reject) => {
        request_({
            url: url,
            method: opts['method'] || 'GET',
            baseURL: opts['baseURL'],
            headers: opts['headers'],
            params: opts['params'],
            data:opts['data'],
            withCredentials: false,
            timeout: 600000,
        }).then((result) => {
            resolve(result.data)
        }).catch((error) => {
            const res = error.response;
            reject(res);
        })
    })
}

export default do_request